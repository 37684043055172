import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    return (
        <nav>
            <Link to='/'>Home</Link>
            <Link to='/about'>About</Link>
            <a href='/blackjack'>Blackjack</a>
            <a href='/engine'>Engine</a>
        </nav>
    );
}

export default Navbar;
