import './styles/Home.css';

function About() {
    return (
        <div>
            <div className='spacer'></div>
            <div>
                <h1>This site is a work in progress!</h1>
                <p>I am not a web dev and certainly not a frontend dev, so bear with me please.</p>
            </div>
        </div>
    );
}

export default About;
