import './styles/Home.css'

function Home() {
    return (
        <div>
            <div className='spacer'></div>
            <div className='container'>
                <img src='/doggo.gif' alt='Doggo'/>
            </div>
            <div className='container'>
                <p>Please type in your credit card information below!</p>
            </div>
            <div className='container'>
                <input type='number' placeholder='Credit Card Number'/>
                <input type='date'/>
                <input type='number' placeholder='CVV' className='cvv'/>
                <input type='submit' value='Submit'/>
            </div>
        </div>
    );
}

export default Home;
